/**
 * @generated SignedSource<<283bbf29dafd832b8b11f10bba8466a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksContentItemProvidersContent$data = {
  readonly __typename: "Video";
  readonly " $fragmentSpreads": FragmentRefs<"contentActionBarContent" | "contentAuthorImageContent" | "contentAuthorLinkContent" | "contentCoverImageContent" | "contentCoverVideoContent" | "contentHeadlineContent" | "contentLiveTagContent" | "contentPrintHeadlineContent" | "contentPublishedDateContent" | "contentReadingTimeContent" | "contentSectionsContent" | "contentSponsorTagContent" | "contentSubHeadlineContent" | "contentSummaryContent" | "contentTopicLinkContent" | "videoDurationOverlayVideo">;
  readonly " $fragmentType": "hooksContentItemProvidersContent";
};
export type hooksContentItemProvidersContent$key = {
  readonly " $data"?: hooksContentItemProvidersContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentItemProvidersContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withActionBar"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withAuthorImage"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withAuthorLink"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImage"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageLandscape250x99"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize1200x1200"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize1200x800"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize1280x720"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize320x180"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize320x240"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize500x500"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize540x360"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize640x360"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize660w"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize768x768"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize800x1200"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverImageSize80x80"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withCoverVideo"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withHeadline"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withLiveTag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withPrintHeadline"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withPublishedDate"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withReadingTime"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSections"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "withSponsorTag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSubHeadline"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSummary"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withTopicLink"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withVideoDurationOverlay"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksContentItemProvidersContent",
  "selections": [
    {
      "condition": "withActionBar",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentActionBarContent"
        }
      ]
    },
    {
      "condition": "withAuthorImage",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentAuthorImageContent"
        }
      ]
    },
    {
      "condition": "withAuthorLink",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentAuthorLinkContent"
        }
      ]
    },
    {
      "condition": "withCoverImage",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "withLandscape250x99",
              "variableName": "withCoverImageLandscape250x99"
            },
            {
              "kind": "Variable",
              "name": "withSize1200x1200",
              "variableName": "withCoverImageSize1200x1200"
            },
            {
              "kind": "Variable",
              "name": "withSize1200x800",
              "variableName": "withCoverImageSize1200x800"
            },
            {
              "kind": "Variable",
              "name": "withSize1280x720",
              "variableName": "withCoverImageSize1280x720"
            },
            {
              "kind": "Variable",
              "name": "withSize320x180",
              "variableName": "withCoverImageSize320x180"
            },
            {
              "kind": "Variable",
              "name": "withSize320x240",
              "variableName": "withCoverImageSize320x240"
            },
            {
              "kind": "Variable",
              "name": "withSize500x500",
              "variableName": "withCoverImageSize500x500"
            },
            {
              "kind": "Variable",
              "name": "withSize540x360",
              "variableName": "withCoverImageSize540x360"
            },
            {
              "kind": "Variable",
              "name": "withSize640x360",
              "variableName": "withCoverImageSize640x360"
            },
            {
              "kind": "Variable",
              "name": "withSize660w",
              "variableName": "withCoverImageSize660w"
            },
            {
              "kind": "Variable",
              "name": "withSize768x768",
              "variableName": "withCoverImageSize768x768"
            },
            {
              "kind": "Variable",
              "name": "withSize800x1200",
              "variableName": "withCoverImageSize800x1200"
            },
            {
              "kind": "Variable",
              "name": "withSize80x80",
              "variableName": "withCoverImageSize80x80"
            }
          ],
          "kind": "FragmentSpread",
          "name": "contentCoverImageContent"
        }
      ]
    },
    {
      "condition": "withCoverVideo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentCoverVideoContent"
        }
      ]
    },
    {
      "condition": "withHeadline",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentHeadlineContent"
        }
      ]
    },
    {
      "condition": "withLiveTag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentLiveTagContent"
        }
      ]
    },
    {
      "condition": "withPrintHeadline",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentPrintHeadlineContent"
        }
      ]
    },
    {
      "condition": "withPublishedDate",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentPublishedDateContent"
        }
      ]
    },
    {
      "condition": "withSections",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentSectionsContent"
        }
      ]
    },
    {
      "condition": "withSubHeadline",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentSubHeadlineContent"
        }
      ]
    },
    {
      "condition": "withSummary",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentSummaryContent"
        }
      ]
    },
    {
      "condition": "withTopicLink",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentTopicLinkContent"
        }
      ]
    },
    {
      "condition": "withReadingTime",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentReadingTimeContent"
        }
      ]
    },
    {
      "condition": "withSponsorTag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentSponsorTagContent"
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "condition": "withVideoDurationOverlay",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "videoDurationOverlayVideo"
            }
          ]
        }
      ],
      "type": "Video",
      "abstractKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "9d447beadce0c2db205689806ef236bb";

export default node;
