import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { topicLinkTopic$key } from "scmp-app/queries/__generated__/topicLinkTopic.graphql";

import { StyledEntityLink } from "./styles";

export type Props = {
  className?: string;
  onClick?: (name: string) => void;
  preferShortName?: boolean;
  reference: topicLinkTopic$key;
};

export const TopicLink: FunctionComponent<Props> = ({
  className,
  onClick,
  preferShortName = false,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicLinkTopic on Topic {
        ...entityLink
        name
        shortName
      }
    `,
    reference_,
  );

  return (
    <StyledEntityLink
      className={className}
      reference={topic}
      onClick={() => {
        onClick?.(topic.name);
      }}
    >
      {preferShortName ? (topic.shortName ?? topic.name) : topic.name}
    </StyledEntityLink>
  );
};

TopicLink.displayName = "TopicLink";
